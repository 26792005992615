<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Actividades Generales</span>
	  		    <v-spacer></v-spacer>

            <v-btn
              color="orange"
              dark
              class="text-capitalize mr-2"
              @click="getReporteSemanal()"
              small
              tile
            >
              Reporte %
            </v-btn>

            <v-btn
              color="orange"
              dark
              class="text-capitalize mr-2"
              @click="getUsuariosSinReporte()"
              small
              tile
            >
              Sin reporte
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

	  		  </v-card-title>
	  		  <v-card-text class="black--text">
	  		  	<v-row >
	  		  		<v-col cols="12" md="4" lg="2">
	  		  			<v-text-field
	  		  			  label="Fecha"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  type="date"
	  		  			  v-model="fecha"
	  		  			></v-text-field>
	  		  		</v-col>

	  		  		<v-col cols="12" md="6" lg="5">
		  		  		<v-autocomplete 
		  		  			filled 
		  		  			dense 
		  		  			clearable 
		  		  			v-model="usuario" 
		  		  			:items="usuarios"
									label="Selecciona un usuario" 
									hide-details
									item-text="datos_completos" 
									item-value="id_usuario"
                  return-object
								>
								</v-autocomplete>
	  		  		</v-col>

	  		  		<v-col cols="12" md="6" lg="5">
                <v-autocomplete 
                  filled 
                  dense 
                  clearable 
                  v-model="id_auxiliar" 
                  :items="usuariosAuxiliares"
                  label="Selecciona un auxiliar" 
                  hide-details
                  item-text="datos_completos" 
                  item-value="id_usuario"
                  return-object
                >
                </v-autocomplete>
              </v-col>
	  		  	</v-row>

	  		  	<!-- Estatus -->
	  		  	<v-row>

              <!-- Estilizar este apartado -->
              <v-col cols="12" md="6" lg="2" class="align-center text-center">
                <v-card class="shadowCard pa-2">
                  <b>Actividades</b>    
                  <br/>
                  {{ actividades.length }}
                  <br/>
                  <br/>
                  <b>Realizadas</b>     
                  <br/>
                  {{ actividades.filter( el => el.cumplio == 1 ).length }}
                  <v-progress-linear
                    :value="(actividades.filter( el => el.cumplio == 1 ).length / actividades.length ) * 100 "
                    color="deep-purple accent-4"
                  ></v-progress-linear>
                  <br/>
                  <b>Sin realizar</b>   
                  <br/>
                  {{ actividades.filter( el => !el.cumplio ).length }}
                  <v-progress-linear
                    :value="(actividades.filter( el => !el.cumplio == 1 ).length / actividades.length ) * 100 "
                    color="primary"
                  ></v-progress-linear>
                  <br/>
                  <b>% Cumplimiento</b> 
                  <br/>
                  {{ actividades.filter( el => el.cumplio == 1 ).length ? ( (actividades.filter( el => el.cumplio == 1 ).length / actividades.length)*100 ).toFixed(1) : 0 }}
                  <v-progress-linear
                    :value="actividades.filter( el => el.cumplio == 1 ).length ? ( (actividades.filter( el => el.cumplio == 1 ).length / actividades.length)*100 ).toFixed(1) : 0 "
                    :color="colorCumplimiento"
                  ></v-progress-linear>

                  <span class="text-h3 mt-2">{{ emojisCalifica }}</span>
                </v-card>
              </v-col>

              <!-- APARTADOS DE KPIS -->
              <v-col cols="12" md="6" class="black--text" align="center">

                <!-- KPI DE MARKETING -->
                <v-card color="shadowCard text-center" v-if="usuario && usuario.puesto == 'Coord. Acad. Adultos'">
                  <b class="black--text text-h6 pt-6">{{ getdatosUsuario.puesto }}</b>
                  <br/>
                  <br/>
                  <b>Objetivo: {{ objetivo }} </b>
                  <br/>
                  <br/>
                  <b>KPI</b>
                  <v-card-title primary-title>
                    <v-spacer></v-spacer>
                    <div> 
                      <v-card color="red darken-2" dark class="pa-4 text-center text-h3 shadowCard">
                        {{ totalFastM ? totalFastM.inscritos : '' }}
                      </v-card>
                      Fast
                    </div>
                    <v-spacer></v-spacer>
                    <div> 
                      <v-card color="red darken-2" dark class="pa-4 text-center text-h3 shadowCard">
                        {{ totalInbiM ? totalInbiM.inscritos : '' }}
                      </v-card>
                      Inbi
                    </div>
                    <v-spacer></v-spacer>
                  </v-card-title>

                  <v-card-text>
                    Valor aceptable: 
                  </v-card-text>

                  <v-card-text class="black--text text-h6 pt-0">
                    Comercial
                  </v-card-text>

                  <v-card-text class="black--text pt-0">
                    VENTAS NUEVAS TOTALES
                  </v-card-text>

                  <v-btn 
                    color="blue"
                    absolute
                    top
                    right
                    icon
                    @click="dialogComercial.estatus = true"
                  >
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                </v-card>

                <!-- KPI DE TI -->
                <v-card color="shadowCard text-center" v-if="usuario && usuario.puesto == 'Jefe de TI'">
                  <b class="black--text text-h6 pt-6">{{ puesto }}</b>
                  <br/>
                  <br/>
                  <b>Objetivo: {{ objetivo }} </b>
                  <br/>
                  <br/>
                  <b>KPI</b>
                  <v-card-title primary-title>
                    <v-spacer></v-spacer>
                    <div> 
                      <v-card color="green darken-2" dark class="pa-4 text-center text-h3 shadowCard">
                        4.0
                      </v-card>
                    </div>
                    <v-spacer></v-spacer>
                  </v-card-title>

                  <v-card-text>
                    Valor aceptable: 3
                  </v-card-text>
                  
                  <v-card-text class="black--text text-h6 pt-0">
                    TI
                  </v-card-text>

                  <v-card-text class="black--text pt-0">
                    ÍNDICE DE SATISFACCIÓN DEL USUARIO
                  </v-card-text>

                  <v-btn 
                    color="blue"
                    absolute
                    top
                    right
                    icon
                    @click="dialogTI.estatus = true"
                  >
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                </v-card>

                <!-- FINANZAS -->
                <v-card color="shadowCard text-center" v-if="usuario && usuario.puesto == 'Analista de Egresos'">
                  <b class="black--text text-h6 pt-6">{{ getdatosUsuario.puesto }}</b>
                  <br/>
                  <br/>
                  <b>Objetivo: {{ objetivo }} </b>
                  <br/>
                  <br/>
                  <b>KPI</b>
                  <v-card-title primary-title>
                    <v-spacer></v-spacer>
                    <div> 
                      <v-card :color="finanzas.cumplio ? finanzas.cumplio >= 1 ? 'green' : 'red darken-2' : 'red darken-2'" dark class="pa-4 text-center text-h3 shadowCard">
                        {{ finanzas.cumplio ? finanzas.cumplio >= 1 ? 'SI' : 'NO' : 'NO' }}
                      </v-card>
                    </div>
                    <v-spacer></v-spacer>
                  </v-card-title>

                  <v-card-text>
                    Valor aceptable: 1
                  </v-card-text>
                  
                  <v-card-text class="black--text text-h6 pt-0">
                    Finanzas
                  </v-card-text>

                  <v-card-text class="black--text pt-0">
                    ÍNDICE DE EFICIENCIA FINANCIERA
                  </v-card-text>

                  <v-btn 
                    color="blue"
                    absolute
                    top
                    right
                    icon
                    @click="dialogFinanzas.estatus = true"
                  >
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                </v-card>
              </v-col>

              <v-col cols="12" md="4">
                <v-card class="shadowCard">
                  <v-data-table
                    :headers="headersReporte"
                    :items="reporte"
                    class="elevation-0"
                    :mobile-breakpoint="100"
                    dense
                    hide-default-footer
                  >
                  </v-data-table>
                </v-card>
              </v-col>
	  		  	</v-row>

            <v-row>
              <v-col cols="12" class="align-center text-center">
                <span class="text-h4 mt-2 semi-bold">{{ textoAvance }}</span>
                <v-progress-linear
                  :value="actividades.filter( el => el.cumplio == 1 ).length ? ( (actividades.filter( el => el.cumplio == 1 ).length / actividades.length)*100 ).toFixed(1) : 0 "
                  :color="colorCumplimiento"
                  height="15"
                  rounded
                ></v-progress-linear>

              </v-col>
            </v-row>

	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="actividades"
							    class="elevation-0"
							    :search="search"
							    :mobile-breakpoint="100"
			            dense
							  >
							    <!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.activo_sn="{ item }">
							      <v-chip 
							      	v-if="item.activo_sn == 1"
							      	small
							      	color="green"
							      	dark
							      >Si</v-chip>

							      <v-chip 
							      	v-else
							      	small
							      	color="error"
							      	dark
							      >No</v-chip>
							    </template>

                  <template v-slot:item.hora_inicio="{ item }">
                    {{ item.hora_inicio }}
                    <br/>
                    a
                    <br/>
                    {{ item.hora_final }}
                  </template>

                  <template v-slot:item.tiempo="{ item }">
                    {{ item.tiempo.substring( 0,5) }}
                  </template>

							    <template v-slot:item.estatus="{ item }">
							    	<v-chip v-if="item.estatus == 0" color="grey" small dark>Pendiente</v-chip>
							    	<v-chip v-if="item.estatus == 1" color="green" small dark>Terminada</v-chip>
							    	<v-chip v-if="item.estatus == 2" color="orange" small dark>En proceso</v-chip>
							    </template>

							    <template v-slot:item.cumplio="{ item }">
							    	<v-checkbox 
							    		v-model="item.cumplio" 
							    		:value="1"
							    		@change="updateActividad( item ) "
							    	></v-checkbox>
							    </template>
							  
							    <template v-slot:item.actions="{ item }">
							    	<v-btn 
							    		color="primary" 
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-magnify</v-icon>
							    	</v-btn>

							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>


    <v-dialog
      v-model="dialogUsuariosSinReporte"
      width="600"
    >
      <v-card>

        <!-- PAGO DUPLICADO -->
        <v-card-title primary-title>
          Trabajadores sin reporte diario
        </v-card-title>

        <!-- PAGO OFICIAL -->
        <v-card-text class="black--text">
          <v-data-table
            :headers="headersSinReporte"
            :items="usuarioSinReporte"
            class="elevation-0"
            :search="search"
            :mobile-breakpoint="100"
            dense
          >
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-btn
            @click="dialogUsuariosSinReporte = false"
            rounded
            color="black"
            dark
            class="text-capitalize"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <v-dialog
      v-model="dialogReporteSemanal"
      width="1000"
    >
      <v-card>

        <!-- PAGO DUPLICADO -->
        <v-card-title primary-title>
          Reporte Semanal
        </v-card-title>

        <!-- PAGO OFICIAL -->
        <v-card-text class="black--text">
          <v-data-table
            :headers="headersReporteSemanal"
            :items="reporteSemanal"
            class="elevation-0"
            :search="search"
            :mobile-breakpoint="100"
            dense
          >
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-btn
            @click="dialogReporteSemanal = false"
            rounded
            color="black"
            dark
            class="text-capitalize"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <!-- RH -->
    <JefeRecursos   
      v-if="dialogRh.estatus"
      :dialogRh="dialogRh"
      :rh="rh"
      :cicloInf="cicloInf"
      :fechaini="fechaini"
      :fechafin="fechafin"
    />

    <!-- COMERCIAL -->
    <JefeComercial   
      v-if="dialogComercial.estatus"
      :dialogComercial="dialogComercial"
      :totalFastM="totalFastM"
      :totalInbiM="totalInbiM"
      :cicloInf="cicloInf"
      :fechaini="fechaini"
      :fechafin="fechafin"
    />

    <!-- Finanzas -->
    <JefeFinanzas   
      v-if="dialogFinanzas.estatus"
      :dialogFinanzas="dialogFinanzas"
      :finanzas="finanzas"
      :cicloInf="cicloInf"
      :fechaini="fechaini"
      :fechafin="fechafin"
    />

    <!-- TI -->
    <JefeTI   
      v-if="dialogTI.estatus"
      :dialogTI="dialogTI"
      :finanzas="finanzas"
      :cicloInf="cicloInf"
      :fechaini="fechaini"
      :fechafin="fechafin"
    />

    <!-- TI -->
    <JefeOperaciones   
      v-if="dialogOperaciones.estatus"
      :dialogOperaciones="dialogOperaciones"
      :finanzas="finanzas"
      :cicloInf="cicloInf"
      :fechaini="fechaini"
      :fechafin="fechafin"
    />

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  // COMPONENTES PARA EL DESGLOSE
  import JefeRecursos          from '@/components/kpi/JefeRecursos'
  import JefeComercial         from '@/components/kpi/JefeComercial'
  import JefeFinanzas          from '@/components/kpi/JefeFinanzas'
  import JefeTI                from '@/components/kpi/JefeTI'
  import JefeOperaciones       from '@/components/kpi/JefeOperaciones'

  export default {

    mixins: [ validarErrores, funcionesExcel ],

  	components:{
      Alerta,
      carga,
      JefeRecursos,
      JefeComercial,
      JefeFinanzas,
      JefeTI,
      JefeOperaciones,
    },

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,

			fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      editedIndex: -1,

      editedItem: {
        idactividades_diarias:0,
				id_usuario:0,
				actividad:'',
				hora_inicio:'',
				hora_final:'',
				cumplio:0,
				objetivo:'',
				actividades:'',
				enfoque:'',
				kpi:'',
				fecha_creacion:null,
				fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      },

      defaultItem: {
        idactividades_diarias:0,
				id_usuario:'',
				actividad:'',
				hora_inicio:'',
				hora_final:'',
				cumplio:0,
				objetivo:'',
				actividades:'',
				enfoque:'',
				kpi:'',
				fecha_creacion:null,
				fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      },

      // tabla
			search:'',
      actividades: [],
      headers: [
        { text: 'H. Inicio'     , value: 'hora_inicio'      , align: 'center'},
        { text: 'Tiempo'        , value: 'tiempo'           },
        { text: 'Actividad'     , value: 'actividad'        },
        { text: '¿Cumplió?'     , value: 'cumplio'          },
        { text: 'Objetivo'      , value: 'objetivo_text'    },
        // { text: 'Estatus'       , value: 'estatus'          },
        // { text: 'Actions'       , value: 'actions', sortable: false },
      ],
      usuario: null,
			usuarios:[],
      headersReporte: [
        { text: 'Objetivo'     , value: 'objetivo'      },
        { text: 'Tiempo'       , value: 'tiempo_total'  },
        { text: 'Cumplió'      , value: 'tiempo_realizado'  },
        { text: '%'            , value: 'porcentaje'    },
      ],

      objetivo:'',
      clasificaciones:[],
      reporte:[],
      puesto:'',
      community: null,
      rh: null,
      finanzas: null,
      sistemas: null,
      academico: null,
      soporte: null,
      finanzasAuxi: null,
      rhAuxi: null,

      rh: { },
      totalFast: 0,
      totalInbi: 0,
      finanzas:{},
      totalFastM: 0,
      totalInbiM: 0,

      dialogRh:{
        estatus: false
      },
      dialogComercial:{
        estatus: false
      },
      dialogFinanzas:{
        estatus: false
      },
      dialogTI:{
        estatus: false
      },
      rhAuxi:{},

      totalFastRI: 1.3,
      totalInbiRI: 1.8,

      dialogOperaciones:{
        estatus: false
      },
      fechaini: null,
      fechafin: null,
      cicloInf: null,

      usuarioSinReporte: [],
      dialogUsuariosSinReporte: false,
      headersSinReporte: [
        { text: 'Trabajador'   , value: 'nombre_completo'  },
        { text: 'Puesto'       , value: 'puesto'           },
      ],


      reporteSemanal:[],
      dialogReporteSemanal: false,
      headersReporteSemanal: [
        { text: 'Trabajador'   , value: 'nombre_completo'  },
        { text: 'Puesto'       , value: 'puesto'           },
        { text: 'lunes'        , value: 'lunes'            },
        { text: 'martes'       , value: 'martes'           },
        { text: 'miercoles'    , value: 'miercoles'        },
        { text: 'jueves'       , value: 'jueves'           },
        { text: 'viernes'      , value: 'viernes'          },
        { text: 'sabado'       , value: 'sabado'           },
      ],

      id_auxiliar: 0,

    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar salón' : 'Editar salón'
      },

      colorCumplimiento( ){

        let cumplimiento = this.actividades.filter( el => el.cumplio == 1 ).length ? ( (this.actividades.filter( el => el.cumplio == 1 ).length / this.actividades.length)*100 ) : 0 

        if( cumplimiento <= 33 ){
          return 'red'
        }else if( cumplimiento <= 90 ){
          return 'orange'
        }else{
          return 'green'
        }
      },


      emojisCalifica( ){

        let cumplimiento = this.actividades.filter( el => el.cumplio == 1 ).length ? ( (this.actividades.filter( el => el.cumplio == 1 ).length / this.actividades.length)*100 ) : 0 

        if( cumplimiento <= 33 ){
          return '😰'
        }else if( cumplimiento <= 90 ){
          return '🫠'
        }else{
          return '🤩'
        }
      },


      textoAvance( ){

        let cumplimiento = this.actividades.filter( el => el.cumplio == 1 ).length ? ( (this.actividades.filter( el => el.cumplio == 1 ).length / this.actividades.length)*100 ) : 0 
        
        if( cumplimiento <= 0 ){
          return 'Aún no empiezas 😡'
        }else if( cumplimiento <= 33 ){
          return 'Mirada que juzga 😑'
        }else if( cumplimiento <= 50 ){
          return 'Aún te falta mucho 🫤'
        }else if( cumplimiento <= 75 ){
          return 'Animóooooo Bruce! Bruce! Bruce! 😁'
        }else if( cumplimiento <= 90 ){
          return 'Ya meritoooo 🙂'
        }else{
          return 'Lo lograste, eres un Campeón 🤩'
        }
      },

      usuariosAuxiliares( ){

        if( this.usuario ){

          let idpuestos = this.usuariosOrganigrama.filter( el => el.idpuesto_jefe == this.usuario.idpuesto ).map((registro) => { return registro.idpuesto_auxiliar })

          console.log( this.usuarios.filter( el => idpuestos.includes( el.idpuesto )) )
        
          return this.usuarios.filter( el => idpuestos.includes( el.idpuesto ) && el.activo_sn == 1 )
        
        }

        return []
      }

    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

      // fecha( ){
      // 	if( this.fecha ){ this.initialize() }
      // },

      usuario( val ){
        if( val ){

          this.getActividadesPorUsuario( val )
          this.objetivo = val.objetivo
          this.puesto   = val.puesto

        }else{
          this.initialize()
        }
      },

      fecha( ){
        if( this.usuario ){

          this.getActividadesPorUsuario( this.usuario )
          this.objetivo = this.usuario.objetivo
          this.puesto   = this.usuario.puesto

        }else{
          this.initialize()
        }
      }

    },

    async created () {
      await this.initialize()
      await this.getUsuarios( )
      await this.getUsuariosOrganigrama( )
    },
    

    methods: {
      initialize () {
      	this.cargar = true
        this.actividades = []

        const payload = {
        	id_usuario: this.getdatosUsuario.iderp,
        	fecha:      this.fecha
        }

        if( this.usuario ){

          if( this.id_auxiliar ){
            return this.getActividadesPorUsuario( this.id_auxiliar )

          }else{
            return this.getActividadesPorUsuario( this.usuario )
          }
        }

        return this.$http.post('actividades.get.jefes', payload).then(response=>{
        	this.actividades = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getActividadesPorUsuario ( val ) {
        this.cargar = true
        this.actividades = []

        const payload = {
          id_usuario: val.id_usuario,
          fecha:      this.fecha,
          idpuesto:   val.idpuesto
        }

        return this.$http.post('actividades.get', payload).then(response=>{
          this.actividades     = response.data.actividades
          this.clasificaciones = response.data.clasificaciones
          this.reporte         = response.data.reporte
          this.rh              = response.data.rh
          this.finanzas        = response.data.finanzas
          this.sistemas        = response.data.sistemas
          this.academico       = response.data.academico
          this.soporte         = response.data.soporte
          this.community       = response.data.community
          this.finanzasAuxi    = response.data.finanzasAuxi
          this.rhAuxi          = response.data.rhAuxi
          this.fechaini        = response.data.fechaini
          this.fechafin        = response.data.fechafin
          
          this.getNI( )

          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getUsuariosOrganigrama( ){
        this.usuariosOrganigrama = []

        return this.$http.get('usuarios.organigrama').then(response=>{
          this.usuariosOrganigrama = response.data

          console.log( this.usuariosOrganigrama )
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getUsuarios( ){
      	this.usuarios = []
    		this.$http.get('usuarios.all.erp').then(response=>{
    			for(const i in response.data){
    				response.data[i].datos_completos = response.data[i].datos_completos.toUpperCase() 
    			}
    			this.usuarios = response.data
    		}).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      save () {
      	if(this.editedItem.horario == ''){
      		this.validarErrorDirecto('Favor de llenar todos los campos')
      	}
      	// Cargamos al usuario que hizo el cambio
      	this.editedItem['id_usuario'] = this.getdatosUsuario.iderp
	      this.cargar = true

        if (this.editedIndex > -1) {
	        // Lo mandapos por el EP
          this.$http.put('actividades.update/' + this.editedItem.idactividades_diarias, this.editedItem).then(response=>{
          	this.validarSuccess( response.data.message )
          	this.initialize()
        		this.close()
	        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

        } else {
	        // Lo mandapos por el EP
          this.$http.post('actividades.add', this.editedItem).then(response=>{
          	this.initialize()
	        	this.cargar = false
        		this.close()
	        }).catch( error =>{
          	this.validarError( error.response.data.message )
        	}).finally( () => { this.cargar = false })
        }
      },

      updateActividad( item ){
      	this.cargar = true

      	this.$http.put('actividades.update/' + item.idactividades_diarias, item).then(response=>{
        	this.validarSuccess( response.data.message )
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      getNI(){
        var payload = { 
          fechaini: this.cicloInf ? this.cicloInf.fecha_inicio_ciclo : this.fechaini, 
          fechafin: this.cicloInf ? this.cicloInf.fecha_fin_ciclo : this.fechafin, 
        }

        this.$http.post('nuevas.matriculas',payload).then(response=>{
          console.log('ohh')
          // Guardar los datos 
          this.totalFast        = response.data.totalFast 
          this.totalInbi        = response.data.totalInbi
          this.totalFastM       = response.data.merca.find( el => el.escuela == 2 )
          this.totalInbiM       = response.data.merca.find( el => el.escuela == 1 )

          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getUsuariosSinReporte( ){
        this.cargar = true
        this.usuarioSinReporte = []

        const payload = {
          fecha:      this.fecha,
        }

        return this.$http.post('actividades.get.sin.reporte', payload).then(response=>{
          this.usuarioSinReporte     = response.data
          this.dialogUsuariosSinReporte = true
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      getReporteSemanal( ){
        this.cargar = true
        this.reporteSemanal = []

        const payload = {
          fecha:      this.fecha,
        }

        return this.$http.post('actividades.get.reporte', payload).then(response=>{
          this.reporteSemanal     = response.data
          this.dialogReporteSemanal = true
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

    },
  }
</script>


